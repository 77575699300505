<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          View Invoice</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <b-row class="mt-1">
      <b-col cols="6">
        <b-form-group
          label="Consultant Name"
          label-cols-md="4"
          label-align="right"
        >
          <div class="form-control">
            <span
              v-for="option in invoice.employee_names"
              :key="option"
            >
              <span v-text="option" />
            </span>
          </div>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group
          label="Period"
          label-cols-md="4"
          label-align="right"
        >
          <span
            class="form-control"
            v-text="invoice.period"
          />
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group
          label="Received Date"
          label-cols-md="4"
          label-align="right"
        >
          <span
            class="form-control"
            v-text="invoice.received_date"
          />
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group
          label="Amount"
          label-cols-md="4"
          label-align="right"
        >
          <span class="form-control">{{ invoice.total_amount | currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group
          label="Number of Hours"
          label-cols-md="4"
          label-align="right"
        >
          <span
            class="form-control"
            v-text="invoice.hours"
          />
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group
          label="Invoice #"
          label-cols-md="4"
          label-align="right"
        >
          <span
            class="form-control"
            v-text="invoice.name"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="6"
      >
        <b-form-group
          label="Expenses"
          label-cols-md="4"
          label-align="right"
        >
          <span class="form-control">{{ invoice.expenses | currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group
          label="Status"
          label-cols-md="4"
          label-align="right"
        >
          <span
            class="form-control"
            v-text="invoice.status"
          />
        </b-form-group>
      </b-col>
      <b-col
        v-if="invoice.reject_reason != null && invoice.status === 'Rejected'"
        cols="6"
      >
        <b-form-group
          label="Reject Reason"
          label-cols-md="4"
          label-align="right"
        >
          <b-form-textarea
            v-model="invoice.reject_reason"
            disabled
            rows="2"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <div v-if="invoice.corrected_invoice != null">
      <hr>
      <div class="d-flex mt-2">
        <h4 class="mb-0 ml-50">
          Corrected Invoice
        </h4>
      </div>
      <b-row>
        <b-col cols="6">
          <b-form-group
            label="Invoice Number"
            label-cols-md="4"
            label-align="right"
          >
            <span
              class="form-control"
              v-text="invoice.corrected_invoice.number"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Invoice Amount"
            label-cols-md="4"
            label-align="right"
          >
            <span class="form-control">{{ invoice.corrected_invoice.amount | currency('$ ', 2, { thousandsSeparator: ',' }) }}</span>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <hr>

    <div class="d-flex mt-2">
      <h4 class="mb-0 ml-50">
        Documents
      </h4>
    </div>
    <b-row
      v-if="invoice.documents!==null"
      class="mt-1"
    >
      <b-col
        cols="11"
        offset="1"
      >
        <b-link
          v-for="option in invoice.documents"
          :key="option.id"
          :value="option.id"
          :href="option.attachment_link"
          :title="option.name"
          target="option.attachment_link"
          :class="{'mt-75': option}"
        >
          <b-badge
            variant="primary"
            class="mr-1"
          >
            <span>{{ option.name }}</span>
          </b-badge>
        </b-link>
      </b-col>
    </b-row>
    <div
      v-if="invoice.documents.length == 0"
      class="text-center"
    >
      <p> No Documents available </p>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BButton, BBadge, BFormTextarea, BLink,
} from 'bootstrap-vue'
import User from '@/js/user'
import Invoices from '@/js/invoices'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BButton,
    BBadge,
    BFormTextarea,
  },
  directives: {
  },
  data() {
    return {
      invoice: {
        name: '',
        period: '',
        user_id: '',
        user_name: '',
        hours: '',
        employee_ids: null,
        employee_names: null,
        total_amount: null,
        total_hours: null,
        status: {},
        documents: [],
      },
    }
  },
  mounted() {
  },
  created() {
    if (!User.profile().timesheet_provider_parents.length > 0) {
      if (User.profile().job_exist) {
        window.location.hash = '#/jobs'
      } else if (User.profile().timesheet_exist) {
        window.location.hash = '#/timesheets'
      } else {
        // Auth.logout()
      }
      return
    }
    Invoices.getInvoiceById(this, this.$route.params.id)
  },
  destroyed() {
  },
  methods: {
    setInvoice(invoice) {
      this.invoice = invoice
    },
    back() {
      this.$router.push('/invoices').catch(() => {})
    },
  },
  setup() {
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
